<template>
  <header class="z-[22] w-full bg-lexmea-blue-400 p-2.5 py-2 md:py-2">
    <div class="flex items-center justify-start md:gap-4">
      <!-- Hamburger menu Icon -->
      <div
        v-if="!showcase"
        class="flex w-40 items-center transition-all duration-100 xl:w-48"
        :class="
          isFocused ? 'max-md:w-0 max-md:gap-0 max-md:opacity-0' : 'max-md:mr-2'
        "
      >
        <button
          ref="hamburgerButton"
          v-tooltip="'Menü'"
          class="landscape:ml-2"
          title="Menü"
        >
          <i-heroicons-bars-3 class="size-8 text-white" />
        </button>
        <LibraryNavigator class="ml-3">
          <span class="sr-only">Zum Bücherregal</span>
          <LexmeaLogo class="aspect-auto h-6 w-[5.5rem]" />
        </LibraryNavigator>
      </div>
      <div
        ref="searchbarElement"
        class="z-10 order-3 w-full flex-grow justify-self-start transition-all duration-100 md:order-2 md:max-w-xl"
        :class="isFocused ? '' : 'mr-2'"
      >
        <SearchBar
          id="step-1"
          class="transition-all duration-300"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
        />
      </div>
      <div
        class="order-2 flex gap-3 transition-all duration-100 md:order-3"
        :class="
          isFocused ? 'max-md:w-0 max-md:gap-0 max-md:opacity-0' : 'max-md:mr-2'
        "
      >
        <LibraryNavigator
          id="step-8"
          v-slot="{ active }"
          v-tooltip="$t('library')"
          class="group relative h-6 w-6 cursor-pointer"
        >
          <LibraryActive
            v-if="active"
            class="h-6 w-6 text-white"
            :alt="$t('library')"
          />
          <LibraryInactive
            v-else
            class="h-6 w-6 text-white"
            :alt="$t('library')"
          />
        </LibraryNavigator>
        <ActiveParagraphLink
          v-slot="{ active }"
          v-tooltip="$t('workspace')"
          class="group relative h-6 w-6 cursor-pointer"
          :class="{ 'animate-bounce': animateBounceWorkspaceButton }"
          :title="$t('workspace')"
        >
          <span class="sr-only">{{ $t("workspace") }}</span>
          <WorkspaceIcon class="h-6 w-6" :filled="active" />
        </ActiveParagraphLink>
      </div>
      <Transition name="fade">
        <div v-if="user && !online" :title="$t('offline')" role="alert">
          <i-material-symbols-wifi-off
            class="size-6 text-gray-100"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t("offline") }}</span>
        </div>
      </Transition>
      <div
        class="relative order-4 ml-auto flex justify-center"
        :class="{ 'max-md:w-0 max-md:gap-0 max-md:opacity-0': isFocused }"
      >
        <button
          v-if="user"
          ref="userDropdownButton"
          :title="$t('settings.word')"
        >
          <UserProfileIcon
            class="h-8 w-8"
            :profileImage="user.profile_image"
            :username="user.name"
          />
        </button>
        <button
          v-else
          v-tooltip="$t('settings.profile.title')"
          class="inline-flex size-7 items-center rounded-full"
          @click="openModal('login')"
        >
          <i-heroicons-user-circle
            class="size-full text-white"
            alt="logged out"
            aria-hidden="true"
          />
        </button>
        <teleport to="#teleported">
          <Transition name="scale">
            <UserDropdown
              v-if="userDropdownOpen && user"
              ref="userDropdown"
              :user="user"
              class="absolute right-4 top-14 z-30 origin-top-right"
              @close="toggleUserDropdown(false)"
              @openFeedback="openFeedback"
            />
          </Transition>
        </teleport>
      </div>
    </div>

    <div v-if="!showcase" ref="hamburgerMenu">
      <!-- Full-screen Menu-->
      <LexmeaFullScreenMenu
        :open="isSmallerThanLg && isHamburgerMenuOpen"
        @close="toggleHamburgerMenu(false)"
      />

      <LexmeaHamburgerMenu
        class="mt-1.5"
        :open="!isSmallerThanLg && isHamburgerMenuOpen"
        @close="toggleHamburgerMenu(false)"
      />
    </div>

    <!-- Full-screen Search -->
    <LexmeaMobileFullScreenSearch
      :open="isSearchModalActive"
      @close="isSearchModalActive = false"
    />
  </header>
</template>

<script lang="ts" setup>
import LibraryActive from "~/assets/header/library-active.svg";
import LibraryInactive from "~/assets/header/library.svg";
import { routes } from "~/routes";

const UserDropdown = defineAsyncComponent(
  () => import("~/components/header/UserDropdown.vue")
);
const LexmeaFullScreenMenu = defineAsyncComponent(
  () => import("~/components/header/LexmeaFullScreenMenu.vue")
);
const LexmeaHamburgerMenu = defineAsyncComponent(
  () => import("~/components/header/LexmeaHamburgerMenu.vue")
);
const LexmeaMobileFullScreenSearch = defineAsyncComponent(
  () => import("~/components/header/LexmeaMobileFullScreenSearch.vue")
);

const showcase = useExamShowcase();

const { isSmallerThanLg } = useLexMeaTailwindBreakpoints();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { openModal } = useLoginModal();

onMounted(() => {
  const searchParams = new URLSearchParams(window.location.search);
  const verificationHappening = searchParams.has("verify_email");
  if (verificationHappening) {
    openModal("register");
  }
});

const { searchbarElement } = useSplitpane();

const {
  isMenuOpen: userDropdownOpen,
  menuElement: userDropdown,
  menuTriggerElement: userDropdownButton,
  toggleMenu: toggleUserDropdown,
} = useMenuToggle();

const {
  isMenuOpen: isHamburgerMenuOpen,
  menuElement: hamburgerMenu,
  menuTriggerElement: hamburgerButton,
  toggleMenu: toggleHamburgerMenu,
} = useMenuToggle();

const isSearchModalActive = ref<boolean>(false);

const { workspace: workspaceRoute } = routes;

const currentPath = useCurrentPath();
const isOnWorkspace = useIsActivePath(workspaceRoute);

// this piece of code is triggered by the CTA button on the product page (component: TrialButton.vue)
const workspaceIconAnimationState = useLocalStorage(
  "workspace-icon-animation",
  "",
  {
    initOnMounted: true,
  }
);
const animateBounceWorkspaceButton = ref(false);

function triggerWorkspaceButtonAnimation() {
  if (isOnWorkspace.value && workspaceIconAnimationState.value) {
    setTimeout(() => {
      workspaceIconAnimationState.value = null;
      animateBounceWorkspaceButton.value = true;
      setTimeout(() => {
        animateBounceWorkspaceButton.value = false;
      }, 3500);
    }, 2500);
  }
}

watch(currentPath, () => {
  toggleHamburgerMenu(false);
  triggerWorkspaceButtonAnimation();
});

const online = useOnline();
const { success, warning } = useToast();

watch(online, (online) => {
  if (online) {
    success("Du bist wieder online.");
  } else {
    warning("Du bist offline, Änderungen werden nicht gespeichert.");
  }
});

const isFocused = ref(false);

const { open: openFeedback } = useFeedbackModal();
</script>
