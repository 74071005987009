<!-- eslint-disable vue/no-v-html -->
<template>
  <ParagraphLink
    class="flex w-full items-start rounded pl-1 pr-2.5 pt-2"
    :legalText="legalTextSlug"
    :legalTextParagraph="paragraphSlug"
    :entry="entry"
  >
    <div class="pr-3.5">
      <ClipboardIcon
        v-if="entry.type === 'schema'"
        class="inline-block h-5 w-5"
        :colorClass="colorFromEntry(entry)"
      />
      <PaperIcon
        v-else-if="entry.type === 'note'"
        class="inline-block h-5 w-5"
        :colorClass="colorFromEntry(entry)"
      />
    </div>
    <div class="flex flex-col gap-0 overflow-x-hidden">
      <div class="truncate text-lg leading-tight">
        <div class="flex items-center gap-1">
          <AnchorIcon class="h-2.5 w-2.5" />
          <div class="block truncate text-xs">
            <span
              v-for="(span, i) in paragraphHighlighted"
              :key="i"
              :class="{ 'font-bold': span.match }"
            >
              {{ span.text }}
            </span>
          </div>
        </div>
        <span class="text-lexmea-pop-darkest" v-html="entry.title" />
        <span
          v-if="entry.type === 'note'"
          class="ml-2 italic"
          v-text="'(' + noteTextFromColor(entry.color) + ')'"
        />
      </div>
      <div class="hidden truncate text-xs sm:block" v-html="entry.text"></div>
    </div>
  </ParagraphLink>
</template>

<script lang="ts" setup>
import type { INote, ISchemaWithText } from "~/models/IUserEntry";

interface Props {
  query: string;
  entry: ISchemaWithText | INote;
}

const props = defineProps<Props>();

const { data: legalText } = useCurrentLegalText();
const {
  query: { data: legalTextParagraph },
} = useCurrentParagraph();

const onlyNavigateToEntry = computed(
  () => props.entry.type === "schema" && legalTextParagraph.value
);

const legalTextSlug = computed(() =>
  onlyNavigateToEntry.value ? legalText.value?.slug : undefined
);

const paragraphSlug = computed(() =>
  onlyNavigateToEntry.value ? legalTextParagraph.value!.slug : undefined
);

const { noteTextFromColor } = useEntryColors();
const { colorFromEntry } = useColorFromEntry();
const { title: paragraphTitle } = useFullParagraphTitle(
  () => props.entry.legal_text_paragraph
);

const { highlighted: paragraphHighlighted } = useHighlighting(
  paragraphTitle,
  toRef(props, "query")
);
</script>
