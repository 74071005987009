import logoBlueIcon from "~/assets/logo-blue.png?url";
import {
  defineOrganization,
  defineWebPage,
  defineWebSite,
  useSchemaOrg,
} from "@unhead/schema-org/vue";

export function useSchemaStructuredData() {
  useSchemaOrg([
    defineOrganization({
      name: "LexMea GmbH",
      logo: logoBlueIcon,
    }),
    defineWebSite({
      name: "LexMea",
    }),
    defineWebPage(),
  ]);
}
