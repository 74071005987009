import { breakpointsTailwind } from "@vueuse/core";

const desktopBreakpoint = "lg";
const tabletBreakpoint = "md";
const mobileBreakpoint = "sm";

export default function useLexMeaTailwindBreakpoints() {
  // always use 1024 as initial width since using the proper SSR width breaks things
  // the problem is that, when the user is on desktop but sends a mobile user agent,
  // the sidenav breaks
  // this does not happen the other way around
  const breakpoints = useBreakpoints(breakpointsTailwind, { ssrWidth: 1024 });
  return {
    isSmallerThanSm: breakpoints.smaller(mobileBreakpoint),
    isSmallerThanMd: breakpoints.smaller(tabletBreakpoint),
    isSmallerThanLg: breakpoints.smaller(desktopBreakpoint),
  };
}
