<template>
  <Avatar :size>
    <AvatarImage :src="cloudStorageUrl(`public/${profileImage}`)" />
    <AvatarFallback>
      {{ initials }}
    </AvatarFallback>
  </Avatar>
</template>

<script lang="ts" setup>
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  type AvatarVariants,
} from "~/components/ui/avatar";
import { cloudStorageUrl } from "~/utils/cloudStorage";

interface Props {
  profileImage?: string;
  username?: string;
  size?: AvatarVariants["size"];
}

const props = defineProps<Props>();

const initials = computed(() => props.username?.substring(0, 2).toUpperCase());
</script>
