import { InferSeoMetaPlugin } from "@unhead/addons";

export async function createUnhead() {
  const { createHead } = await (import.meta.env.SSR
    ? import("@unhead/vue/server")
    : import("@unhead/vue/client"));
  return createHead({
    plugins: [InferSeoMetaPlugin()],
  });
}
