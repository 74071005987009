<template>
  <template v-if="flagComponent">
    <component v-bind="$attrs" :is="flagComponent" />
    <span class="sr-only">{{ tier }}</span>
  </template>
</template>

<script setup lang="ts">
import CircleFlagsDe from "~icons/circle-flags/de";
import CircleFlagsEu from "~icons/circle-flags/eu";
import FlatColorIconsGlobe from "~icons/flat-color-icons/globe";
import NRW from "~/assets/flags/nrw.svg?component";
import { type LegalTextTier } from "~/models/ILegalText";

const props = defineProps<{
  tier: LegalTextTier;
}>();

const iconComponents: Record<LegalTextTier, Component> = {
  DE: CircleFlagsDe,
  EU: CircleFlagsEu,
  INT: FlatColorIconsGlobe,
  STATE: NRW,
};

const flagComponent = computed(() => iconComponents[props.tier]);
</script>
