<template>
  <component :is="iconComponent" alt="LexMea Logo" />
</template>

<script setup lang="ts">
import LogoBlueIcon from "~/assets/logo-blue.svg";
import LogoIcon from "~/assets/logo.svg";

const { isBlue = false } = defineProps<{
  isBlue?: boolean;
}>();

const iconComponent = computed(() => (isBlue ? LogoBlueIcon : LogoIcon));
</script>
