import { toast } from "vue-sonner";

export function useToast() {
  const toastInfo = useLocalStorage<string | null>("toast", null, {
    initOnMounted: true,
  });

  const persist = (content: string, options: { type: string }) => {
    toastInfo.value = JSON.stringify({
      type: options.type,
      message: content,
    });
  };

  return { ...toast, persist, toastInfo };
}
