<template>
  <form class="flex flex-col gap-7" @submit.prevent="submit">
    <div class="flex flex-col gap-4 md:gap-7">
      <div>
        <LexmeaInput
          v-model="form.email"
          name="email"
          autocomplete="username"
          :error="errors.email"
          :label="t('authentication.input.emailorUsername')"
          type="text"
          required
        />
        <i18n-t
          v-if="resendEmail"
          class="text-sm text-maroon-flush"
          tag="p"
          keypath="resendEmailButton"
        >
          <span
            class="link cursor-pointer font-bold underline"
            @click="onResendEmail"
          >
            {{ t("resendEmail") }}
          </span>
        </i18n-t>
      </div>
      <LexmeaInput
        v-model="form.password"
        autocomplete="current-password"
        name="password"
        :error="errors.password"
        :label="t('authentication.input.password')"
        type="password"
        required
        minlength="8"
      />
      <div class="flex items-center justify-between gap-4 md:gap-0">
        <LexmeaCheckbox
          v-model="form.remember"
          name="remember"
          :error="errors.remember"
        >
          <span class="text-sm">{{ t("rememberMe") }}</span>
        </LexmeaCheckbox>
        <button
          v-if="!showcase"
          class="link text-sm font-bold"
          type="button"
          @click="goToForgetPassword"
        >
          {{ $t("authentication.input.forgotPassword") }}
        </button>
      </div>
    </div>
    <div class="flex justify-between gap-4">
      <DataProtectionIcon
        :alt="t('cookies.data_protection_icon')"
        class="h-[3.625rem] w-12 flex-shrink-0 p-1"
      />
      <i18n-t keypath="loginDescription" tag="p" class="text-[9px] md:text-xs">
        <LocaleLink
          v-if="!showcase"
          class="text-de font-bold text-lexmea-blue-400 underline"
          :href="privacyNotice"
          target="_blank"
        >
          {{ t("more") }}
        </LocaleLink>
        <span v-else>
          {{ t("more") }}
        </span>
      </i18n-t>
    </div>

    <!-- should not be filled by user -->
    <input type="hidden" class="law_firm_from" value="" />

    <div
      class="flex py-4"
      :class="showcase ? 'justify-end' : 'justify-between'"
    >
      <LexmeaButton
        v-if="!showcase"
        class="!font-bold hover:bg-white"
        design="link"
        type="button"
        @click="$emit('switchTab')"
      >
        {{ t("toRegister") }}
      </LexmeaButton>
      <LexmeaButton type="submit" :loading="loading">
        {{ t("authentication.login") }}
      </LexmeaButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import DataProtectionIcon from "~/assets/authentication/dataProtectionShield.svg";
import { ApiClientError } from "~/api/apiClient";
import { routes } from "~/routes";

const { t } = useI18n();

type LoginForm = {
  password: string;
  email: string;
  remember: boolean;
};

const emit = defineEmits<{
  switchTab: [];
  close: [];
  emailResent: [email: string];
}>();

const showcase = useExamShowcase();

const { login: _login, resendEmailCode } = useUserStore();
const errors = ref<Partial<Record<keyof LoginForm, string>>>({});
const resendEmail = ref(false);

const form = reactive<LoginForm>({
  email: "",
  password: "",
  remember: false,
});

const loading = ref(false);

const submit = async () => {
  const honeypotValue = <HTMLInputElement>(
    document.querySelector(".law_firm_from")
  );
  if (honeypotValue.value !== "") {
    // Form submission is spam
    return;
  }

  errors.value = {};
  resendEmail.value = false;
  loading.value = true;
  try {
    await _login(form);
    emit("close");
  } catch (reason) {
    if (reason instanceof ApiClientError) {
      const err = reason.errors;
      if (err) {
        errors.value = err;
        // if combination exists but email was not verified, the response
        // has an error with an account_verified array with first item of false
        const { account_verified } = err;
        if (account_verified && account_verified[0] === false) {
          resendEmail.value = true;
        }
      }
    }
  }
  loading.value = false;
};
// TODO: Fix the error messages so they are localized
const onResendEmail = async () => {
  errors.value = {};
  loading.value = true;
  resendEmail.value = false;
  await resendEmailCode({ email: form.email });
  loading.value = false;
  emit("emailResent", form.email);
};

const navigate = useNavigate();
const { forgotPassword, privacyNotice } = routes;
const goToForgetPassword = async () => {
  let path = forgotPassword;

  if (form.email) {
    path += `?email=${form.email}`;
  }

  emit("close");
  await navigate(path);
};
</script>

<i18n>
{
  "de": {
    "resendEmailButton": "Hier kannst du die E-Mail {0}",
    "resendEmail": "erneut versenden",
    "rememberMe": "Eingeloggt bleiben",
    "loginDescription": "Wir wenden höchste Datenschutzstandards an und verarbeiten deine Daten auf europäischen Servern. Daher ist auch ein Login mit Google oder Facebook leider nicht möglich. Erfahre {0} mehr darüber.",
    "more": "hier",
    "toRegister": "Zur @:authentication.registration.word"
  },
  "en": {
    "resendEmailButton": "Here you can {0} the email",
    "resendEmail": "resend",
    "rememberMe": "Remember me",
    "loginDescription": "We apply the highest data protection standards and process your data on European servers. Therefore, a login with Google or Facebook is unfortunately not possible. Learn more about it {0}.",
    "more": "here",
    "toRegister": "To @:authentication.registration.word"
  }
}
</i18n>
