<template>
  <footer
    class="flex w-full items-center justify-between bg-wild-sand px-2 text-4.5pt max-sm:hidden max-lg:landscape:hidden"
  >
    <span class="hidden md:inline-block"
      >© LexMea {{ new Date().getFullYear() }}</span
    >
    <div class="flex items-center space-x-4">
      <LanguageToggle size="sm" />

      <button
        v-show="onboardingTour.canShowTour.value"
        id="tour-btn"
        class="underline"
        @click="onboardingTour.startTour"
      >
        {{ $t("tour") }}
      </button>
      <button class="underline" @click="openFeedback">
        {{ $t("feedback") }}
      </button>
      <LocaleLink
        v-for="item in footerItems"
        :key="item.href"
        class="underline"
        :href="item.href"
      >
        {{ item.title }}
      </LocaleLink>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const onboardingTour = useOnboardingTour();
const { open: openFeedback } = useFeedbackModal();
const { footerItems } = useLexmeaLinks();
</script>
