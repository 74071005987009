<template>
  <LexmeaHeader />
  <div class="fixed top-12 z-10 h-min w-full overflow-hidden md:top-14">
    <LexmeaProgressBar :hidden="progressHidden" />
  </div>
  <main
    ref="mainContainer"
    class="w-full grow overflow-auto"
    :class="{
      'overflow-x-hidden': !showLexmeaFooter,
      'scrollbar-color-normal': isOnLandingPages,
    }"
  >
    <Transition
      enterFromClass="translate-x-[200%] opacity-0"
      leaveToClass="translate-x-[200%] opacity-0"
      enterActiveClass="transition duration-300"
      leaveActiveClass="transition duration-300"
    >
      <GoUpFAB v-if="showGoUpButton" />
    </Transition>
    <FeedbackFAB />
    <slot />
  </main>

  <ImportLocalEntriesModal />
  <LexmeaCookieBanner v-if="cookieBannerTimeout" />

  <LexmeaFooter v-if="showLexmeaFooter" />

  <PwaHandler />

  <Toaster richColors closeButton position="top-right" />

  <VueQueryDevtools buttonPosition="bottom-left" />

  <ModalsContainer />
</template>

<script lang="ts" setup>
import "~/scss/index.scss";
import "floating-vue/dist/style.css";
import "vue-final-modal/style.css";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { ModalsContainer } from "vue-final-modal";
import { routes } from "~/routes";
import { loadLocaleMessages } from "~/i18n";
import { clientOnly } from "vike-vue/clientOnly";

const i18n = useI18n({ useScope: "global" });
const pageContext = usePageContext();
const userStore = useUserStore();
const localeCookie = useCookie("locale")(pageContext);

watch(
  () => pageContext.locale,
  async (newLocale, oldLocale) => {
    if (oldLocale !== newLocale) {
      if (!i18n.availableLocales.includes(newLocale)) {
        await loadLocaleMessages(i18n, newLocale);
      }

      i18n.locale.value = newLocale;
      if (userStore.loggedIn) {
        await userStore.updateUser({ preferred_language: newLocale });
      } else {
        localeCookie.value = newLocale;
      }
    }
  }
);

const currentPath = useCurrentPath();
const {
  library: { base: libraryBase },
  workspace,
} = routes;
const libraryActive = useIsActivePath(libraryBase);
const workspaceActive = useIsActivePath(workspace);

const isOnLandingPages = logicNot(logicOr(libraryActive, workspaceActive));

const FeedbackFAB = clientOnly(
  () => import("~/components/floatingButtons/FeedbackFAB.vue")
);

const ImportLocalEntriesModal = clientOnly(
  () => import("~/components/modal/ImportLocalEntriesModal.vue")
);

const LexmeaCookieBanner = clientOnly(
  () => import("~/components/LexmeaCookieBanner.vue")
);

const PwaHandler = clientOnly(() => import("~/components/PwaHandler.vue"));

const Toaster = clientOnly(() => import("~/components/ui/sonner/Sonner.vue"));

useAuth();
useSchemaStructuredData();
useSeoHead();
usePosthog();

watchEffect(() => {
  if (!import.meta.env.SSR) {
    window._paq?.push(["setCustomUrl", currentPath.value]);
    window._paq?.push(["trackPageView"]);
  }
});

const mainContainer = ref<HTMLElement | null>(null);
const { y } = useScroll(mainContainer);

const showGoUpButton = ref(false);

watch(currentPath, () => {
  y.value = 0;
});

watch(
  () => y.value,
  () => {
    const viewportHeight = window.innerHeight;
    showGoUpButton.value = y.value > viewportHeight;
  }
);

const showcase = useExamShowcase();
const showLexmeaFooter = logicAnd(
  logicNot(showcase),
  logicOr(libraryActive, workspaceActive)
);

// hack for vh
const { height } = useWindowSize();
watchEffect(() => {
  if (import.meta.env.SSR) return;
  // do it here because we can't inject dynamic properties to :root
  document.documentElement.style.setProperty(
    "--total-height",
    `${height.value}px`
  );
});

watchEffect(() => {
  if (import.meta.env.SSR) return;
  // do it here because we can't inject dynamic properties to :root
  document.documentElement.style.setProperty(
    "--footer-height",
    showLexmeaFooter.value ? "1rem" : "0px"
  );
});

const { transitionStartHook, transitionEndHook } = usePageTransition();

const progressHidden = ref(true);
transitionStartHook.on(() => (progressHidden.value = false));
transitionEndHook.on(() => (progressHidden.value = true));

const cookieBannerTimeout = useTimeout(5000);
</script>

<style lang="postcss" global>
:root {
  --header-height: 3rem;

  @screen md {
    --header-height: 3.5rem;
  }

  --main-height: calc(
    var(--total-height) - var(--header-height) - var(--footer-height)
  );
}

.v-popper--theme-tooltip {
  .v-popper__inner {
    @apply max-w-lg bg-lexmea-tooltip px-4;

    /* increase the breakpoint to enlarge the tooltip width */
    @media (max-width: 576px) {
      max-width: 100% !important;
    }
  }
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  @apply border-lexmea-tooltip !important;
}

.v-popper--theme-tooltip .v-popper__arrow-inner {
  @apply border-lexmea-tooltip !important;
}
</style>
